<template>
  <AuthLayout :tenant-id="tenantId" :portal-id="portalId">
    <div id="portals-signin">
      <div id="company-logo" class="cursor-pointer justify-center q-mb-md">
        <img :src="getTenantLogo()" />
      </div>
      <div class="description">Hi, Welcome!</div>
      <div class="title">Sign in to your account</div>
      <!-- portal form -->
      <div class="col">
        <template
          v-if="loginType === 'MASTER_LOGIN' && passwordTypes === 'PASSWORD'"
        >
          <div id="masterLogin">
            <ValidationObserver ref="form">
              <ValidationProvider
                v-slot="{ errors }"
                name="username"
                :rules="{ required: true }"
              >
                <TextField
                  v-model="username"
                  label="username"
                  is-mandatory
                  :auto-focus="true"
                  :is-readonly="isLoading"
                  :error="errors[0]"
                  class="q-mb-md"
                  @enter="validate"
                />
              </ValidationProvider>
              <ValidationProvider
                v-slot="{ errors }"
                name="password"
                :rules="{ required: true, password: true }"
              >
                <PasswordField
                  v-model="password"
                  label="password"
                  is-mandatory
                  :is-readonly="isLoading"
                  :tooltip="_passwordHint"
                  :error="errors[0]"
                  class="q-mb-md"
                  @enter="validate"
                />
              </ValidationProvider>
            </ValidationObserver>

            <div class="row items-center">
              <div id="forgot-password">Forgot password?</div>
            </div>

            <BaseButton
              label="sign in"
              :is-loading="isLoading"
              class="sign-in-btn"
              @click="validate"
            />

            <FormFieldError v-if="error" :error="error" class="text-center" />
          </div>
        </template>
        <template
          v-else-if="
            loginType === 'EMAIL_LOGIN' ||
            (loginType === 'MASTER_LOGIN' &&
              (passwordTypes === 'OTP' || passwordTypes === 'SOCIAL_LOGIN'))
          "
        >
          <EmailAuthentication
            label="Email"
            :tenant-id="tenantId"
            :portal-id="portalId"
            :login-type="loginType"
            :settings="settings"
            :password-types="passwordTypes"
            @logged="logged"
            @showSocialLogin="showSocialLogin"
            @error="userFieldError"
          />

          <FormFieldError v-if="error" :error="error" class="text-center" />
        </template>
        <template v-else-if="loginType === 'MOBILE_LOGIN'">
          <MobileAuthentication label="Mobile" :otp-verify="false" />
        </template>
        <template v-else-if="loginType === 'APP_LOGIN'">
          <template v-if="checkTenant">
            <template v-if="!normalLogin">
              <ValidationObserver ref="tenant">
                <Email
                  v-model="email"
                  :is-loading="isLoading"
                  @enter="validateEmail"
                />

                <BaseButton
                  label="continue"
                  :is-loading="isLoading"
                  class="sign-in-btn"
                  @click="validateEmail"
                />
              </ValidationObserver>

              <FormFieldError v-if="error" :error="error" class="text-center" />
            </template>
            <template v-else>
              <ValidationObserver ref="form">
                <Email
                  v-model="email"
                  :is-loading="isLoading"
                  @enter="validateSignIn"
                />

                <Password
                  v-model="password"
                  :is-loading="isLoading"
                  @enter="validateSignIn"
                />

                <BaseButton
                  label="sign in"
                  :is-loading="isLoading"
                  class="sign-in-btn"
                  @click="validateSignIn"
                />
              </ValidationObserver>

              <FormFieldError v-if="error" :error="error" class="text-center" />
            </template>
          </template>
          <template v-else>
            <ValidationObserver ref="form">
              <Email
                v-model="email"
                :is-loading="isLoading"
                @enter="validateSignIn"
              />
              <Password
                v-model="password"
                :is-loading="isLoading"
                @enter="validateSignIn"
              />
            </ValidationObserver>

            <BaseButton
              label="sign in"
              :is-loading="isLoading"
              class="sign-in-btn"
              @click="validateSignIn"
            />

            <FormFieldError v-if="error" :error="error" class="text-center" />
          </template>
        </template>
        <template v-if="loginType === 'APP_LOGIN' && socialLogin.length">
          <OrDivider />
          <SocialAuths
            logged-from="PORTAL"
            :tenant-id="tenantId"
            :social-login-list="socialLogin"
            @logged="logged"
          />
        </template>
        <template v-if="masterSocialLogin">
          <OrDivider />
          <SocialAuths
            logged-from="PORTAL"
            :tenant-id="tenantId"
            :social-login-list="socialLogin"
            @logged="logged"
          />
        </template>
      </div>

      <!-- ... -->
    </div>
  </AuthLayout>
</template>

<script>
import AuthLayout from "@/layouts/auth/AuthLayout.vue";
import { ValidationObserver, ValidationProvider } from "vee-validate";
import TextField from "@/components/common/form/text-field/TextField.vue";
import PasswordField from "@/components/common/form/password-field/PasswordField.vue";
import EmailAuthentication from "@/components/common/form/email-authentication-field/EmailAuthentication.vue";
import MobileAuthentication from "@/components/common/form/mobile-authentication-field/MobileAuthentication.vue";
import OrDivider from "@/components/common/OrDivider.vue";
import SocialAuths from "@/views/sign-in/components/SocialAuths.vue";
import FormFieldError from "@/components/common/form/FormFieldError.vue";
import { passwordHint } from "@/helpers/password-hint.js";
import axios from "axios";
import { auth } from "@/api/factory.js";
import Email from "@/views/sign-in/components/Email.vue";
import Password from "@/views/sign-in/components/Password.vue";
import * as Msal from "msal";

export default {
  name: "PortalsSignIn",

  components: {
    AuthLayout,
    ValidationObserver,
    ValidationProvider,
    TextField,
    PasswordField,
    EmailAuthentication,
    MobileAuthentication,
    OrDivider,
    SocialAuths,
    FormFieldError,
    Email,
    Password,
  },

  props: {
    tenantId: {
      type: String,
      required: true,
    },

    portalId: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      portalWidth: "720px",
      submissionModal: false,
      isFocused: false,
      title: "Track Portal",
      description: "To submit and track your request",
      settings: {},
      loginType: "",
      username: "",
      password: "",
      socialLogin: [],
      isLoading: false,
      error: "",
      email: "",
      normalLogin: false,
      passwordTypes: "",
      masterSocialLogin: false,
    };
  },

  computed: {
    _passwordHint() {
      return passwordHint;
    },

    checkTenant() {
      let origin = location.origin;
      if (
        origin === "https://edmsuat.sobhaapps.com" ||
        origin === "https://edms.sobhaapps.com"
      ) {
        return true;
      }
      return false;
    },
  },

  watch: {
    portalId: {
      immediate: true,
      handler() {
        if (this.portalId) {
          this.getPortal();
        }
      },
    },
  },

  methods: {
    toggleWidth() {
      this.portalWidth = this.portalWidth === "100%" ? "720px" : "100%";
    },

    logged() {
      let origin = location.origin;
      if (
        origin === "https://demo.ezofis.com" ||
        origin === "http://52.172.32.88"
      ) {
        if (this.portalId == 1) {
          this.$router.push({
            name: "portals-access-custom",
          });
        } else {
          this.$router.push({
            name: "portals-access",
          });
        }
      } else {
        this.$router.push({
          name: "portals-access",
        });
      }
    },

    userFieldError(errorMsg) {
      this.error = errorMsg;
    },

    showSocialLogin() {
      this.masterSocialLogin = true;
    },

    async getPortal() {
      this.$store.commit("showLoadingBar");
      try {
        const response = await axios({
          url: `${process.env.VUE_APP_API_URL}/portal/withoutToken/${this.tenantId}/${this.portalId}`,
          method: "GET",
        });
        const { status, data } = response;
        this.$store.commit("hideLoadingBar");

        if (status !== 200) {
          throw response;
        }
        if (data) {
          this.title = data.name;
          this.description = data.description;
          if (data.settingsJson) {
            this.settings = JSON.parse(data.settingsJson);
            this.loginType =
              this.settings.authentication.loginType || "EMAIL_LOGIN";
            this.passwordTypes = this.settings.authentication.passwordTypes;
            this.socialLogin = this.settings.authentication.socialLogin;
          } else {
            this.loginType = "EMAIL_LOGIN";
          }
        }
      } catch (error) {
        this.$alert.error("Error fetching portal");
        console.error(error);
      }
    },

    async validate() {
      const areAllFieldsValid = await this.$refs.form.validate();

      if (!areAllFieldsValid) {
        return;
      }

      this.signIn();
    },

    async validateEmail() {
      const areAllFieldsValid = await this.$refs.tenant.validate();

      if (!areAllFieldsValid) {
        return;
      }

      this.isLoading = true;

      const payload = {
        email: this.email,
      };
      const { error, data } = await auth.emailValidate(1, payload);

      if (error) {
        this.error = error;
        this.isLoading = false;
        return;
      }
      this.isLoading = false;
      if (data === "User") {
        this.normalLogin = true;
      } else if (data === "ADUser") {
        this.microsoftSignIn();
      }
    },

    async signIn() {
      this.isLoading = true;

      const payload = {
        tenantId: this.tenantId,
        portalId: this.portalId,
        email: this.username,
        password: this.password,
        formId: this.settings.authentication.formId,
        emailColumn: this.settings.authentication.usernameField,
        passwordColumn: this.settings.authentication.passwordField,
        nameColumn: this.settings.authentication.firstnameField,
      };

      const { error } = await auth.portalLogin(payload);

      if (error) {
        this.error = error;
        this.isLoading = false;
        return;
      }

      this.logged();
    },

    async validateSignIn() {
      const areAllFieldsValid = await this.$refs.form.validate();
      if (!areAllFieldsValid) {
        return;
      }
      this.signInPortal();
    },

    async signInPortal() {
      this.isLoading = true;
      const payload = {
        email: this.email,
        password: this.password,
        loggedFrom: "WEB",
        portalId: this.portalId,
      };
      const { error } = await auth.login(payload, this.tenantId);
      if (error) {
        this.error = error;
        this.isLoading = false;
        return;
      }
      this.$router.push({ name: "portals-access" });
    },

    async microsoftSignIn() {
      let clientId = "315d2d09-9633-419e-8e85-18b747eb5be5";
      let origin = location.origin;
      if (origin === "https://edmsuat.sobhaapps.com") {
        clientId = "9db78754-f584-459d-a04d-f5a6d9b1e2cf";
      } else if (origin === "https://edms.sobhaapps.com") {
        clientId = "a403995e-94b6-4805-bee7-2fa606a751bd";
      }

      // Microsoft Authentication
      const mAuth = new Msal.UserAgentApplication({
        auth: {
          clientId: clientId,
          redirectUri: origin,
        },
      });
      await mAuth.loginPopup({
        scopes: ["user.read"],
        loginHint: this.email,
      });
      const user = mAuth.getAccount();
      let email = user.userName;
      // Microsoft Authentication
      console.log(email);

      this.isLoading = true;

      const payload = {
        tenantId: this.tenantId,
        email: email,
        loggedFrom: "WEB",
      };

      const { error } = await auth.socialLogin(payload);

      if (error) {
        this.error = error;
        this.isLoading = false;
        return;
      }

      this.logged();
    },

    getTenantLogo() {
      return `${process.env.VUE_APP_API_URL}/tenant/logo/${this.tenantId}`;
    },
  },
};
</script>

<style lang="scss" scoped>
#portals-signin {
  margin: auto;

  .logo img {
    height: 24px;
    width: auto;
  }

  .description {
    color: var(--icon-color-inverted);
    margin-bottom: 4px;
  }

  .title {
    @extend .text-lg;
    font-weight: bold;
    color: var(--title-1-color);
    margin-bottom: 12px;
  }

  .header {
    padding: 16px;
    border-radius: 4px;
    background-color: var(--component-bg-color);
    border-top: 4px solid var(--primary);
    margin-bottom: 16px;
    display: flex;
    align-items: center;

    .description {
      @extend .text-sm;
      color: var(--icon-color-inverted);
    }
  }
  .headerBody {
    padding: 45px;
    border-radius: 4px;
    background-color: var(--component-bg-color);
    border-top: 4px solid var(--primary);
    margin-bottom: 16px;
    margin-top: 16px;
    display: flex;
    align-items: center;
  }

  .footer {
    margin-top: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    left: 0;
    bottom: 16px;
    width: 100%;

    .label {
      @extend .text-sm;
      color: var(--icon-color-inverted);
      margin-right: 4px;
    }

    img {
      height: 16px;
      width: auto;
    }
  }

  #masterLogin {
    input {
      min-height: 46px;
    }
  }

  #forgot-password {
    color: var(--secondary);
    font-weight: 500;

    &:hover {
      cursor: pointer;
    }
  }

  .sign-in-btn {
    min-height: 44px;
    width: 100%;
    margin-top: 18px;
  }
}

#modal .imgCenter {
  display: flex;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
}

.social-auth-btns {
  display: flex;
  align-items: center;
}

.social-auth-btn {
  flex: 1;
  height: 44px;
  // padding: 0px 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  background-color: var(--component-bg-color-inferior);
  color: var(--icon-color) !important;
  font-weight: 600;
  text-transform: capitalize;
  cursor: pointer;

  &:nth-child(2) {
    margin-left: 8px;
  }
}

#company-logo {
  display: flex;
  align-items: center;

  img {
    height: 70px;
    width: 150px;
  }
}
</style>
